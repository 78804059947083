import React from "react"

export default ({ children }) => (
  <div id="sidebar-right" className="sidebar">
    <div id="block-block-1" className="clear-block block block-block">
      <div className="content">
        {children}
      </div>
    </div>
  </div>
)
