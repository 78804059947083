import React from "react"
import { Link } from "gatsby"
const ListLink = props => (
  <li className="leaf">
    <Link to={props.to}>{props.children}</Link>
  </li>
)
export default ({ children }) => (
  <ul className="menu">
    <ListLink to="/technique/toddlers">Toddlers</ListLink>
    <ListLink to="/technique/3-year-old">3 year old</ListLink>
    <ListLink to="/technique/4-year-old">4 year old</ListLink>
    <ListLink to="/technique/5-year-old">5 year old</ListLink>
    <ListLink to="/technique/6-7-year-old">6-7 year old</ListLink>
    <ListLink to="/technique/8-10-year-old">8-10 year old</ListLink>
    <ListLink to="/technique/11-and-older">11 and above</ListLink>
  </ul>
)


