import React from "react"
import Layout from "../../components/layout-ages"

export default () => (
  <Layout title="11 and Older">
    <h2>11 and Older</h2>
    <div className="clear-block">
      <div id="node-19" className="node">


        <div className="content clear-block">
          <p><img src="/images/007-alexandra-300x225.jpg" className="float-left" width="300"
                  height="225" alt=""/> Our studio had quite a surprise this last year. A family had contacted us to begin
            lessons for their 8 year old son. We discussed the Animal Note method, and it was decided this method would
            be great for this young man. When the 11 year brother and 14 year old sister saw the music they asked to
            start too. The next week a 14 year old friend of the sister joined the group. It was amazing how well the
            Animal Note method worked for these older children. Within 8 months the two girls had finished all the books
            in the series and began playing music in the intermediate level of standard music. They were so excited, and
            their friends were so impressed with their playing. They were also thankful for the quick start the Animal
            Notes gave them and how much fun it is to play music for themselves and others. The 11 year old boy took a
            year to finish the series of books, partly because he was also taking guitar lessons using our Animal Note
            Guitar book. Practice time was split between the two instruments. He entered standard music books at the
            “Big Note” level in piano and plays his favorite melodies on his guitar. I had not thought about using the
            Animal Note method for older children unless they were having trouble note reading, but this experience has
            proven otherwise. </p>
          <p>We now have another beginning 14 year old who chose to start with the Animal Note method and is making
            great progress. She is so excited and is already playing “Jingle Bells” in standard notation. Practicing is
            no problem for her because she understands and enjoys what she is doing at the keyboard. She is hoping she
            will be given a guitar for Christmas.</p>
          <p>Another family had just started with our studio because they had heard we had a method that would help
            their children, ages 14, 12, 11 and 9, straighten out note reading problems that were causing the children
            great difficulty and they wanted to quit. Using mainly the “Animal Note Flash/ standard note flash card set”
            and the ”Moving On – One” book, the children are well on their way to conquering the problems they were
            experiencing. </p>
          <p>You also might find the Animal Note method is right for your older beginners, or children that are having
            trouble reading standard music notation. It does not take them as long to get the basics under control, and
            they end up playing on a level they are comfortable with earlier than I have ever experienced.</p>
          <h3>Testimonial</h3>
          <p><i>"I love learning with Noteimals, because it is so easy to remember and lots of fun too!"<br/>
            - Elizabeth - age 11</i></p>
          <p>After 4 months of music, Elizabeth reports that she is playing Christmas songs with notes in both hands
            written in standard music notation. </p>
        </div>

        <div className="clear-block">
          <div className="meta">
          </div>

        </div>

      </div>
    </div>
  </Layout>
)
